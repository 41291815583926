import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import EdeContent from "../components/ede-content/edeContent"

const Ede = () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa | Ethical Digital Entreprenuer</Title>
      <Meta
        name="description"
        content="Codetrain's Ethical Digital Entrepreneur program is 
        a four-week class aimed at teaching entrepreneurs or aspiring 
        entrepreneurs what it takes to run a sustainable and profitable 
        business in this current digital economy"
      />
      <EdeContent />
    </MainLayout>
  )
}

export default Ede
