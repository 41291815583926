import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const EdeContentHero = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "img/hero/ede.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          style={{ height: "65vh" }}
          backgroundColor={`#040e18`}
        >
          <div className="container">
            <div className="row">
              <div className="col-7"></div>
              <div className="col">
                <div className="ede-hero-content">
                  <h1 className="text-uppercase">
                    Ethical Digital Entreprenuer
                  </h1>
                  <br />
                  <h5>
                    Transform your business idea into a money maker in 4 weeks.
                  </h5>
                  <p>
                    <a
                      href="https://forms.gle/JDwHTBRamH398sub8"
                      className="btn btn-custom-primary-large"
                    >
                      Register Now!
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default EdeContentHero
