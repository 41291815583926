import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import EdeContentHero from "./edeHero"
import "./edeContent.scss"

const EdeContent = () => (
  <StaticQuery
    query={graphql`
      query {
        yaa: file(relativePath: { eq: "img/full-photos/yaa-ede.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        shubham: file(relativePath: { eq: "img/full-photos/shubham-ede.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <section>
          <div className="section-container ede-content">
            <div className="ede-content__hero">
              <EdeContentHero />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span className="mdi mdi-timer-sand text-5 ede-icon"></span>
                        </div>
                        <div className="col-9">
                          <h5 className="ede-subheading">Program Duration</h5>
                          <p>4 weeks</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span className="mdi mdi-chart-scatterplot-hexbin text-5 ede-icon"></span>
                        </div>
                        <div className="col-9">
                          <h5 className="ede-subheading">Projects Lab</h5>
                          <p>2 weeks</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col">
                          <span className="mdi mdi-coin text-5 ede-icon"></span>
                        </div>
                        <div className="col-9">
                          <h5 className="ede-subheading">Fees</h5>
                          <p>GHS 950</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-ede">
            <div className="container">
              <div className="row">
                <div className="col-10 offset-md-1">
                  <h4 className="text-center text-capitalize ede-heading">
                    Turn your business idea into a money maker
                  </h4>
                  <p className="text-center">
                    The Ethical Digital Entrepreneur program is a four-week
                    class aimed at teaching entrepreneurs or aspiring
                    entrepreneurs what it takes to run a sustainable and
                    profitable business in this current digital economy.
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-lg-7 order-lg-5">
                      <Img
                        fluid={data.shubham.childImageSharp.fluid}
                        alt="Ethical digital entreprenuer"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-5  mt-4">
                      <h5 className="text-capitalize ede-subheading">
                        What will you get from the program?
                      </h5>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        Turn your idea into a lucrative product or service.
                      </p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        Roll out a winning digital marketing strategy.
                      </p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        Secure international funding and mentorship.
                      </p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        Become the ideal job candidate by understanding how
                        business works.
                      </p>
                      <p>
                        <a
                          href="https://forms.gle/JDwHTBRamH398sub8"
                          className="btn btn-custom-primary-normal"
                        >
                          Register Now!
                        </a>
                      </p>
                    </div>
                  </div>
                  <div
                    className="row ede-program-row"
                  >
                    <div className="col">
                      <Img
                        fluid={data.yaa.childImageSharp.fluid}
                        alt="Ethical digital entreprenuer"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-5 mt-4">
                      <h5 className="text-capitalize ede-subheading">
                        Who is this program for?
                      </h5>
                      <p>The program is the perfect fit for</p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        anyone who has started a business and needs some help
                        with it.
                      </p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        anyone who has a business idea and isn’t sure how to
                        start.
                      </p>
                      <p>
                        <span
                          className="mdi mdi-check"
                          style={{ color: "rgb(36, 152, 162)" }}
                        ></span>{" "}
                        anyone who wants to set themselves up to be a valuable
                        employee or potential entrepreneur.
                      </p>
                      <p>
                        <a
                          href="https://forms.gle/JDwHTBRamH398sub8"
                          className="btn btn-custom-primary-normal"
                        >
                          Register Now!
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }}
  />
)

export default EdeContent
